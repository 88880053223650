:root {
  --spacing-s: 2.25rem;
  --spacing-m: 4rem;
  --spacing-l: 6rem;
  --spacing-xl: 7.5rem;
  --spacing-2xl: 11.25rem;

  --section-spacing: 6.25rem;

  --gap: 8px;

  @media (--large) {
    --section-spacing: 11.25rem;
    --gap: clamp(6px, 0.878vw, 12px);
  }
}
