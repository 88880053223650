/* Font scale */

:root {
  --font-size-fixed-22: var(--font-size-22);
  --font-size-fixed-3xs: 0.625rem;
  --font-size-fixed-2xs: 0.75rem;
  --font-size-fixed-xs: 0.875rem;
  --font-size-fixed-s: 0.875rem;
  --font-size-fixed-m: 1rem;
  --font-size-fixed-l: 1.5625rem;
  --font-size-fixed-xl: 1.6rem;

  --font-size-5xs: 0.375rem; /* 6px */
  --font-size-4xs: 0.4375rem; /* 7px */
  --font-size-menu-item: 0.6875rem; /* 11px */
  --font-size-3xs: 0.75rem; /* Caption - 12px */
  --font-size-2xs: 0.75rem; /* X-Small - 12px */
  --font-size-xs: 0.75rem; /* Small - 12px */
  --font-size-15: 0.8125rem; /* 13px */
  --font-size-s: 0.875rem; /* Heading 5 / Paragraph - 14px */
  --font-size-18: 1rem; /* 16px */
  --font-size-m: 1.125rem; /* Heading 4 - 18px */
  --font-size-22: 1.25rem; /* - 20px */
  --font-size-l: 1.25rem; /* Heading 3 - 20px */
  --font-size-xl: 1.5rem; /* Heading 2 - 24px */
  --font-size-28: 1.625rem; /* Heading 2 - 26px */
  --font-size-38: 1.75rem; /* Title 1 - 28px */
  --font-size-2xl: 1.875rem; /* Heading 1 - 30px */
  --font-size-3xl: 3.125rem; /* Heading 1 - 40px */

  --font-size-button: var(--font-size-3xs);
  --font-size-button-small: var(--font-size-4xs);

  --font-size-xtra-small: var(--font-size-2xs);
  --font-size-small: var(--font-size-xs);
  --font-size-paragraph: var(--font-size-s);
  --font-size-heading4: var(--font-size-m);
  --font-size-heading3: var(--font-size-xl);
  --font-size-heading2: var(--font-size-2xl);
  --font-size-heading1: var(--font-size-3xl);

  --line-height-xs: 0.8;
  --line-height-s: 1;
  --line-height: 1.1;
  --line-height-l: 1.2;

  /* helpers for Neue Haas Grotesk Display Pro - weight */
  --grot-roman: 600;
  --grot-medium: 500;

  @media (--large) {
    --font-size-fixed-22: 1.375rem; /* - 22px */
    --font-size-fixed-2xs: 0.75rem;
    --font-size-fixed-xs: 0.875rem;
    --font-size-fixed-s: 0.875rem;
    --font-size-fixed-m: 1.25rem;
    --font-size-fixed-l: 1.5625rem;
    --font-size-5xs: 0.5rem; /* 8px */
    --font-size-4xs: 0.625rem; /* 10px */
    --font-size-3xs: 0.6875rem; /* 11px */
    --font-size-2xs: 0.75rem; /* 12px */
    --font-size-menu-item: 0.75rem; /* 12px */
    --font-size-xs: clamp(0.875rem, 1.02vw, 1.2rem); /* 14px */
    --font-size-15: clamp(0.9375rem, 1.09vw, 1.2rem); /*  - 15px */
    --font-size-s: clamp(0.875rem, 1.17vw, 0.9rem); /* 16px */
    --font-size-18: clamp(1.125rem, 1.32vw, 1.5rem); /*  - 18px */
    --font-size-m: clamp(1.25rem, 1.46vw, 100rem); /* Heading 5 - 20px */
    --font-size-22: clamp(1.375rem, 1.61vw, 100rem); /*  - 22px */
    --font-size-l: clamp(1.5625rem, 1.83vw, 100rem); /* Heading 4 - 25px */
    --font-size-28: clamp(1.75rem, 2.05vw, 100rem); /* Heading 3 - 28px */
    --font-size-38: clamp(2.375rem, 2.78vw, 100rem); /* Title 1 - 38px */
    --font-size-xl: clamp(2.5rem, 2.925vw, 100rem); /* Heading 3 - 40px */
    --font-size-2xl: clamp(3.625rem, 4.25vw, 100rem); /* Heading 2 - 58px */
    --font-size-3xl: clamp(5rem, 5.85vw, 10rem); /* Heading 1 - 80px */
  }

  /* denim logic */
  /* --heading-transform: uppercase; */
  --heading-ls: 0;
  --title-ls: 0.02em;
  --typo-weight: 100;
  --body-ls: 0.04em;
  --body-ls-fixed: 0.04em;
  --body-lh: 1.2;
  --tag-ls: 0.1em;
  --body-transform: none;
}

.denim {
  --heading-family: var(--font-canela-deck);
  /* --heading-transform: capatalize; */
  --body-transform: none;
  --heading-ls: -0.03em;
  --typo-weight: 300;
  --title-ls: -0.03em;
  --body-ls: 0.12em;
  --body-lh: 1.25;
}

/* Base values */

html {
  font-size: 16px; /* Rem base */
  line-height: var(--line-height);
  color: var(--color-primary);
  font-family: var(--font-haas-grot-display);
  --heading-family: var(--font-canela);
  .denim {
    --heading-family: var(--font-canela-deck);
  }
}

/* elements */
p,
h1,
h2,
h3,
h4,
h5,
ul {
  @extend %typo-test;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

button {
  font-family: inherit;
}

p {
  font-size: var(--font-size-s);
}

/* Helper classes */
.h1,
.typo-1 {
  @extend %typo-h1;
}

.h2,
.typo-2 {
  @extend %typo-h2;
}

.h3,
.ingress,
.typo-3 {
  @extend %typo-h3;
}

.h4,
.typo-4 {
  @extend %typo-h4;
}

.h5,
.typo-5,
[data-half] .h4 {
  @extend %typo-h5;
}

.t1 {
  @extend %typo-t1;
}

.t2 {
  @extend %typo-t2;
}

.t3 {
  @extend %typo-t3;
}

.t4 {
  @extend %typo-t4;
}

/* body */
.b1 {
  @extend %typo-b1;
}

.b2 {
  @extend %typo-b2;
}

.b3 {
  @extend %typo-b3;
}

.b4 {
  @extend %typo-b4;
}

/* small text
TODO: use tokens */

.menu-text {
  &-A {
    font-size: var(--font-size-3xs);
    line-height: calc(12 / 11);
    letter-spacing: 40%;
  }
  &-B {
    font-size: var(--font-size-4xs);
    line-height: calc(15 / 10);
    letter-spacing: 80%;
  }
}

.button-text {
  &-1 {
    font-family: var(--font-haas-grot-display);
    font-size: var(--font-size-5xs);
    line-height: calc(12 / 8);
    letter-spacing: 200%;
    font-weight: var(--grot-medium);
  }
  &-2 {
    font-family: var(--font-haas-grot-display);
    font-size: var(--font-size-2xs);
    line-height: calc(13 / 12);
    letter-spacing: 120%;
    text-transform: uppercase;
    font-weight: var(--grot-roman);
  }
  &-3 {
    font-family: var(--font-haas-grot-display);
    font-size: var(--font-size-4xs);
    line-height: calc(13 / 10);
    letter-spacing: 120%;
    text-transform: uppercase;
    font-weight: var(--grot-roman);
  }
  &-4 {
    font-family: var(--font-canela-deck);
    font-size: var(--font-size-xs);
    line-height: calc(15 / 14);
    letter-spacing: 40%;
    font-weight: 100;
  }
}

.tag {
  &-A {
    font-size: var(--font-size-2xs);
    line-height: calc(13 / 12);
    letter-spacing: 100%;
    font-weight: var(--grot-roman);
    text-transform: uppercase;
  }
  &-B {
    font-size: var(--font-size-15);
    line-height: calc(18 / 15);
    letter-spacing: 100%;
    font-weight: var(--grot-roman);
    text-transform: uppercase;
  }
  &-C {
    font-size: var(--font-size-2xs);
    line-height: calc(13 / 12);
    letter-spacing: 50%;
    font-weight: var(--grot-roman);
    text-transform: uppercase;
  }
}

/* preamble */
.preamble {
  font-size: 0.75rem !important;
  letter-spacing: var(--tag-ls);
  font-family: var(--font-century-gothic);
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;

  &::before {
    content: "( ";
  }
  &::after {
    content: " )";
  }

  &__noBrackets {
    font-size: 0.75rem !important;
    letter-spacing: var(--tag-ls);
    font-family: var(--font-century-gothic);
    text-transform: uppercase;
    font-weight: 400;
    white-space: nowrap;
  }
}

.hero,
.title-blok {
  & .preamble {
    font-size: 0.9375rem !important;
  }
}

/* transformers */
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic !important;
}
/* truncates text at 1 line */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.underlined {
  text-decoration: underline;
  text-underline-offset: 1px;
}

.underlined-lg {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.text-center {
  text-align: center;
}

.accent-text {
  color: var(--color-accent);
}
