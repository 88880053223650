.dialog-overlay{
  background-color: var(--dialog-overlay-background);
  position: fixed;
  z-index: 900;
  inset: 0;
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog-content{   
  transform: translateX(0%);
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
}









