.root {
  padding-bottom: 1.5rem;
  margin-top: var(--section-margin);

  font-family: var(--font-haas-grot-display);
  font-size: var(--font-size-menu-item);
  font-weight: 300;
  letter-spacing: var(--body-ls-fixed);
}

.footer {
  max-width: var(--page-inset-width);
  margin-inline: auto;
}

.company-link {
  &:after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: var(--color-black);
    transition: width 0.3s;
  }
}

.grid {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.75rem;
  grid-row-gap: 2rem;

  &.socials__grid {
    padding-top: 4rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    @media (--large) {
      grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr;
      grid-template-rows: 1fr;
      grid-gap: 4rem;
    }
  }

  @media (--large) {
    grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 4rem;
  }
}

.col {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (--large) {
    gap: 90px;
  }
}

.newsletter {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-column-start: 1;
  grid-column-end: 3;
  padding-top: 14px;
  @media (--large) {
    padding-top: 0;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 4;
    grid-column-end: 5;
    gap: 90px;
  }
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;

  & svg {
    width: 24px;
    height: 24px;
  }
}

.credentials {
  font-size: var(--font-size-xs);
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 3px;
  grid-row-start: 2;
  grid-row-end: 3;
  @media (--large) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 5;
    grid-column-end: 6;
  }
}

.preamble {
  font-size: var(--font-size-xs) !important;
  font-weight: 100 !important;
}

.flavour__textstyle {
  max-width: 70%;
  @media (--large) {
    padding-top: 1rem;
  }
}
