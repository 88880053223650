* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--color-background);

  &.scroll-locked {
    overflow: hidden;
    &.desktop-locked {
      overflow: hidden;
    }
  }

  &.scroll-locked-alt {
    overflow: hidden;
  }
}

button {
  cursor: pointer;
  background: transparent;
  border: 0;
  color: var(--color-primary);
  padding: 0;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

p {
  & a {
    color: var(--color-primary);
    position: relative;
    opacity: 1;
    transition: opacity 200ms ease;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--color-primary);
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 200ms ease;
    }

    &:hover {
      opacity: 0.6;
      &::after {
        transform: scaleX(1);
      }
    }

    &:active {
      transform: translateY(2px);
    }
  }
}

/* 
 * .sr-only
 * 
 * DEPRECATED: 
 * use the Hidden component instead - leaving this here for backwards compatibility
 *
 * @description:
 * good way to make something accesible to screen readers, but not visible 
*/

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
/*
.react-player > div > div > iframe {
  box-sizing: border-box !important;
  width: 82.777778vh !important;
  height: 56.25vw !important;
  min-width: 100% !important;
  min-height: 100% !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
*/

.react-player iframe {
  /*height: calc(var(--video-height) * 1px) !important;*/
  /*width: calc(var(--video-width) * 1px) !important;*/
  transform: scale(var(--video-zoom));
}

.react-player > div > div {
  height: 100%;
}
