.side-by-side {
  & .product-listing {
    width: 100%;
    @media (--large) {
      & > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        justify-content: space-between;
        padding-inline: 0;
      }
    }
  }
  & .media-gallery {
    & > div > div {
      width: 90vw;
    }
    &[data-odd-children="true"] {
      & > div {
        padding-bottom: 0 !important;
      }
    }
    @media (--large) {
      & > div {
        margin: 0 !important;
        padding-bottom: 20vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        & > div {
          width: auto;
          &:nth-child(2n) {
            transform: translateY(50%);
          }
        }
      }
    }
  }
  & .column-root {
    display: none;
    @media (--large) {
      display: flex;
    }
  }
  & .product-slider {
    display: block;
    @media (--large) {
      display: none;
    }
  }

  & .hero-title > * {
    @extend %typo-h3;
    text-transform: capitalize;
  }

  & .title-blok > h2 {
    @extend %typo-h5;
  }

  & .body-blok {
    & p {
      @extend %typo-b3;
    }
    overflow-wrap: break-word;
    width: 100%;
    &.left {
      margin-left: var(--page-inset);
      margin-right: auto;
    }
    &.right {
      margin-left: auto;
      margin-right: var(--page-inset);
    }
    &.center {
      margin-inline: auto;
    }

    @media (--large) {
      &.left {
        margin-left: 0;
      }
      &.right {
        margin-right: 0;
      }
    }
  }

  & .media-blok {
    &[data-full="false"] {
      max-width: 75%;
      height: min-content;
    }
    &[data-full="true"] {
      width: 100%;
      height: min(40vh, 40rem);
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.article-blok,
.page-blok,
.denim {
  & > .media-blok {
    &[data-alignment="left"] {
      padding-left: var(--page-inset);
    }
    &[data-alignment="right"] {
      padding-right: var(--page-inset);
    }
    &[data-alignment="center"] {
      padding-inline: var(--page-inset);
    }
  }
}

.page-blok {
  & > .title-blok:first-child {
    margin-top: var(--section-margin);
  }
}
