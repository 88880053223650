:root {
  --snackbar-height: 0;
  --header-offset-mobile: 10px;
  --header-height-scroll: 48px;
  --header-height: 48px;
  --header-transition-speed: 250ms;

  @media (--large) {
    /*--header-height: clamp(68px, 4.978vw, 102px);*/
    --header-height: 68px;
  }
}
