/* 
  Let's use the Page and Section components instead of these classes in this project
  - storing these for overview and initial setup
 */

:root {
  --page-width: 100%;
  --section-width: 1434px;
  --content-width: 800px;

  --section-margin: 40px;
  --content-margin: 1.5rem;
  --stacked-margin: 1rem;

  --page-inset: min(20px, 5%); /* General site horizontal margins */

  --page-inset-width: min(
    var(--page-width),
    calc(100% - (var(--page-inset) * 2))
  );
  --section-inset-width: min(
    var(--section-width),
    calc(100% - (var(--page-inset) * 2))
  );
  --content-inset-width: min(
    var(--content-width),
    calc(100% - (var(--page-inset) * 2))
  );

  @media (--small) {
    --page-inset: 32px; /* General site horizontal margins */
  }
  @media (--large) {
    --section-margin: 96px;
  }
}

[data-layout="page"] {
  max-width: var(--page-inset-width);
  margin: 0 auto var(--section-margin);

  & > [data-layout="section"] {
    max-width: var(--section-width);
  }
}

[data-blok] + [data-blok] {
  margin-top: var(--section-margin);
}

.side-by-side[data-blok] + .side-by-side[data-blok] {
  margin-top: 0;
}

[data-blok][data-blok-background][data-layout="full"]
  + [data-blok][data-blok-background][data-layout="full"] {
  margin-top: 0;
}

.navigation-bar + [data-blok]:not([data-blok-background][data-layout="full"]) {
  margin-top: var(--section-margin);
}

.page-wrapper {
  padding-top: calc(var(--header-height) + var(--snackbar-height));
}
