.marquee {
  --marquee-gap: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--color-primary);
  word-spacing: 1px;
  overflow: hidden;
  display: flex;
  @media (--large) {
    min-height: 60px;
  }
}

.marquee__element {
  --mozwebkit-animation-duration: 3s;
  --animation-duration: 30s;
  display: flex;
  flex-direction: row;
  gap: var(--marquee-gap);
  width: auto;
  height: auto;
  margin: 0;
  white-space: nowrap;
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -moz-animation: scroll-first var(--mozwebkit-animation-duration) linear
    infinite;
  -webkit-animation: scroll-first var(--mozwebkit-animation-duration) linear
    infinite;
  animation: scroll-first var(--animation-duration) linear infinite;

  &.second_marquee {
    margin-left: var(--marquee-gap);
    -moz-animation: scroll-second var(--mozwebkit-animation-duration) linear
      infinite;
    -webkit-animation: scroll-second var(--mozwebkit-animation-duration) linear
      infinite;
    animation: scroll-second var(--animation-duration) linear infinite;
  }
}

@-moz-keyframes scroll-first {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-first {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@keyframes scroll-first {
  0% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-moz-keyframes scroll-second {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(calc(-100% - var(--marquee-gap)));
  }
}

@-webkit-keyframes scroll-second {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(calc(-100% - var(--marquee-gap)));
  }
}

@keyframes scroll-second {
  0% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(calc(-100% - var(--marquee-gap)));
    -webkit-transform: translateX(calc(-100% - var(--marquee-gap)));
    transform: translateX(calc(-100% - var(--marquee-gap)));
  }
}
