@media (prefers-reduced-motion: no-preference) {
  :root {
    /* scale */
    --scale: 1.05;
    --scale-large: 1.2;
    --scale-transition: all ease 0.15s;
    --scroll-transition: 800ms;

    /* underline transition */
    --underline-transition: width 0.15s ease;

    /* fade */
    --fade-transition: opacity 0.15s ease;
  }

  /* globals, overwrite to turn off */
  [data-blok][data-animated="fadeY"] {
    transform: translateY(40px);
    transition: all var(--scroll-transition);
    opacity: 0;

    @media (--large) {
      transform: translateY(1.5vw);
    }

    &[data-view="true"] {
      opacity: 1;
      transform: translateY(0);
    }

    @media (prefers-reduced-motion) {
      transform: translateY(0);
      opacity: 1;
    }
  }
  /* --- */

  /* blok specific animations */
  .product-listing[data-animated="custom"] {
    & swiper-slide,
    & .product-card {
      opacity: 0;
      transform: translateY(45px);
      transition:
        transform var(--scroll-transition),
        opacity var(--scroll-transition);

      @media (--large) {
        transform: translateY(2.5vw);
      }

      &:nth-child(2) {
        transition-delay: 150ms;
      }

      &:nth-child(3) {
        transition-delay: 300ms;
      }
    }

    &[data-view="true"] swiper-slide,
    &[data-view="true"] .product-card {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .media-gallery[data-animated="custom"] {
    & .media__gallery__item {
      & .media__gallery__item__content {
        opacity: 0;
        transform: translateY(45px);
        transition:
          transform var(--scroll-transition),
          opacity var(--scroll-transition);
        @media (--large) {
          transform: translateY(2.5vw);
        }
      }
    }
    &[data-view="true"] .media__gallery__item__content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .side-by-side[data-animated="custom"] {
    & .side-by-side-media {
      opacity: 0;
      transition:
        clip-path var(--scroll-transition),
        opacity var(--scroll-transition);
      @media (--large) {
        clip-path: polygon(0% 0%, 80% 0%, 80% 100%, 0% 100%);
      }
    }

    &[data-view="true"] .side-by-side-media {
      opacity: 1;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }

    &.right {
      & .side-by-side-media {
        @media (--large) {
          clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 20% 100%);
        }
      }

      &[data-view="true"] .side-by-side-media {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      }
    }
  }

  .hero-container[data-animated="custom"] {
    & h2 {
      opacity: 0;
      transform: translateY(45px);
      transition:
        transform var(--scroll-transition),
        opacity var(--scroll-transition);

      @media (--large) {
        transform: translateY(2.5vw);
      }
    }

    &[data-view="true"] h2 {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* hover classes */
  .underline-hover {
    position: relative;
    opacity: 1;
    transition: opacity 200ms ease;
    transition-delay: 75ms;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--color-primary);
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 200ms ease;
    }

    &:hover {
      opacity: 0.6;
      &::after {
        transform: scaleX(1);
      }
    }

    &:active {
      transform: translateY(2px);
    }

    &:disabled,
    &.disabled {
      pointer-events: none;
      &:hover {
        opacity: 1;
        &::after {
          transform: scaleX(0);
        }
      }
      &::after {
        display: none;
      }
    }
  }

  /* ---  */

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translateX(200%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @media (prefers-reduced-motion) {
    * {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
}
