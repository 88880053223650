:root {
  /* ------- new ------- */
  --color-white: #ffffff;
  --color-black: #000000;
  --color-brick: var(--color-brick);
  --color-neon-yellow: #ffff00;
  --color-neon-teal: #00f8b6;
  --color-standard-gray-dark: #e6e3dc; /* never seen it used */
  --color-standard-gray: #e7e9eb; /* never seen it used */

  --color-primary: var(--color-black);
  --color-primary-90: #312e2b;
  --color-primary-80: #4e4c45;
  --color-primary-70: #65645d;
  --color-primary-60: #938e84;
  --color-primary-50: #bcb8af;
  --color-primary-40: #d1cfc9;
  --color-primary-20: #e8e5df;
  --color-primary-10: #f2f2ed;

  --color-base: #faf8f2;

  --color-sale-90: #5f2800;
  --color-sale-70: #964000;
  --color-sale-50: #d17f4b;
  --color-sale-30: #e8be9f;
  --color-sale-10: #f2dac8;

  --image-background-warm: #e6e3dc;
  --image-background-cool: #e7e9eb;

  --color-picker-red: #d03c3c;
  --color-picker-orange: #e6e3dc;
  --color-picker-yellow: #f0d874;
  --color-picker-green: #81ad58;
  --color-picker-blue: #679abf;
  --color-picker-pink: #efbecf;
  --color-picker-purple: #b99edd;
  --color-picker-white: var(--color-white);
  --color-picker-grey: #bcb8af;
  --color-picker-beige: #d9caa2;
  --color-picker-brown: #7e5430;
  --color-picker-black: var(--color-black);
  --color-picker-navy: #123258;
  --color-picker-khaki: #9d9d71;

  /* not in design, but we might need for error/success states */
  --color-red-light: hsl(333, 67%, 86.7%);
  --color-red: #ff3333;
  --color-red-dark: hsl(336, 80%, 57.8%);
  --color-green-light: hsl(143, 40.3%, 79%);
  --color-green: hsl(151, 40.2%, 54.1%);
  --color-green-dark: hsl(151, 55%, 41.5%);

  /* property colors */
  --color-box-border: var(--color-primary);

  /* Element colors */
  --color-background: var(--color-base);
  --color-text: var(--color-primary);
  --color-text-light: var(--color-white);
  --color-accent: var(--color-sale-70);
  --color-button-beige: #faf8f2; /* coming from latest design */
  --dialog-overlay-background: rgba(0, 0, 0, 0.3);

  --color-hover: hsla(0, 0%, 0%, 0.6);
  --color-error: var(--color-red);
  --color-success: #54802a;
}

body {
  & .denim {
    --color-background: var(--color-white);
  }
}

.denim {
  --color-background: var(--color-white);
  background: var(--color-white);
}
